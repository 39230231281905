import React, { useState, useEffect, useCallback } from "react";
import { Card, CardHeader } from "semantic-ui-react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    MenuItem,
    Typography,
    FormLabel,
    Box, Table, TableBody, TableCell, TableContainer, TableRow, Paper
} from "@material-ui/core";
import { DialogAction, RequestStatus, WorkspaceDialogResult, WorkspaceRequest } from "../controllers/WorkSpacesController";
import InfoIcon from "@material-ui/icons/Info";


const RenderText = ({ label, value }) => {
    if (value !== undefined && value !== null) {
        return (
            <TextField
                key={label}
                label={label}
                value={value}
                variant="outlined"
                style={{ margin: 5 }}
            //fullWidth
            />
        );
    }
    return null;
};

const CustomDropDown = ({ id, label, value, items, handler }) => (
    <TextField
        id={id}
        label={label}
        value={value}
        onChange={(e) => handler(e.target.value)}
        fullWidth
        style={{ margin: "5px 5px 10px 5px" }}
        select
        variant="standard"
    >
        {items.map((item) => (
            <MenuItem value={item} key={item}>
                {item}
            </MenuItem>
        ))}
    </TextField>
);

const CustomTextField = ({ id, label, value, handler, isError, errorMessage, style }) => (
    <TextField
        id={id}
        label={label}
        value={value}
        onChange={(e) => {
            if (handler) {
                handler(e.target.value)
            }
        }}
        error={isError}
        helperText={isError ? errorMessage : ""}
        fullWidth
        required
        style={style || { margin: "5px 5px 10px 5px" }}
    />
);

const MapDataControl = ({ title, data }) => {
    if (!data) {
        return <div></div>;
    }

    const isObjectAndNotEmpty = (value) => {
        return typeof value === 'object' && value && value !== null && Object.keys(value).length > 0;
    };

    return (
        <div>

            <TableContainer component={Card} style={{ width: "100%" }}>
                <CardHeader style={{ padding: '5px', background: "#f5f5f5" }}>
                    <Typography
                        color="primary"
                        variant="body1"
                    >
                        {title || "Untitled"}
                    </Typography>
                </CardHeader>
                <Table>
                    <TableBody>
                        {Object.entries(data).map(([key, value]) => {
                            if (isObjectAndNotEmpty(value)) {
                                return null;
                            }

                            return (
                                <TableRow key={key}>
                                    <TableCell>{key}</TableCell>
                                    <TableCell>{value || "--"}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};



const BundleDescription = ({ header, description }) => (
    <Box sx={{ display: "block", alignItems: "center" }}>
        <InfoIcon color="primary" />
        <Typography
            color="primary"
            variant="body1"
            id="description"
        >
            {description || "No description available"}
        </Typography>
    </Box>
);

const getTitle = (action) => {
    const actionTitles = {
        [DialogAction.NEW_REQUEST]: "New WorkSpace Request",
        [DialogAction.EDIT_REQUEST]: "Edit WorkSpace",
        [DialogAction.WARNING]: "Warning!",
        [DialogAction.WORKSPACE_DETAILS]: "Workspace Details",
    };
    return actionTitles[action] || "Unknown action";
};


const WorkspaceConfirmationForm = ({ input, setResult }) => {
    const { options } = input;
    const [confirmationText, setConfirmationText] = useState("");

    useEffect(() => {
        let result = { isValid: options.textConfirmationForm ? options.confirmationText === confirmationText : true };
        setResult(result);
    }, [confirmationText, options.confirmationText, setResult]);

    const renderContent = () => {
        if (options.textConfirmationForm) {
            return (
                <div>
                    <Typography style={{ margin: "0.5em" }}>{options.message}</Typography>
                    <CustomTextField
                        id="confirmation-input"
                        label={`Type ${options.confirmationText} to agree.`}
                        value={confirmationText}
                        handler={(value) => {
                            setConfirmationText(value)
                        }}
                        isError={!confirmationText}
                        errorMessage="Confirmation text is required"
                        style={{ margin: "0.5em" }}
                    />
                </div>
            );
        } else {
            return <Typography style={{ margin: "0.5em" }}>{options.message}</Typography>;
        }
    };

    return (
        <div> {renderContent()}</div>
    );
};


const WorkspaceDetailsForm = ({ input, setResult }) => {
    const { action, uiconfig, data } = input;
    const wsRequest = data;
    const { config } = uiconfig
    const bundle = config.bundles[wsRequest.workspace.bundleId];
    const directory = config.directories[wsRequest.workspace.directoryId];

    const getLocalDateString = (date) => {
        return date ? new Date(date).toLocaleDateString() : "-";
    }

    const workspace = {
        State: wsRequest.workspace.state,
        "Directory Id": directory.id,
        "Bundle Id": bundle.bundle_id,
        "Created": getLocalDateString(wsRequest.workspace.createdOn),
        "Workspace Id": wsRequest.workspace.workspaceId,
        "IP Address": wsRequest.workspace.ip,
        "Connection State": wsRequest.workspace.connectionState,
    }

    const compute = {
        "Operating System": bundle.os,
        "Compute": bundle.compute_description,
        "Compute Type": bundle.compute_type,
        "Root Volume": `${bundle.root_volume} GB`,
        "User Volume": `${bundle.user_volume} GB`,
        "Protocol": bundle.protocols[0]
    }

    const review = {
        "Reviewer ": wsRequest.review.reviewer,
        "Reviewed Date": getLocalDateString(wsRequest.review.reviewedOn)
    }

    const request = {
        "Requester ": wsRequest.request.requester,
        "Requested Date": getLocalDateString(wsRequest.request.requestedOn)
    }

    return (
        <Card style={{ width: "auto" }}>
            <Card.Content>
                <div style={{ display: 'block' }}>
                    <div style={{ padding: 5 }}>
                        <Paper>
                            <RenderText
                                label="Request Status"
                                value={wsRequest.status}
                            />
                            <RenderText
                                label="Jira Ticket Id"
                                value={wsRequest.jiraNumber}
                            />
                            <RenderText
                                label="User"
                                value={wsRequest.workspace.user}
                            />
                            <RenderText
                                label="Email"
                                value={wsRequest.workspace.email}
                            />
                        </Paper>
                    </div>
                    <div style={{ padding: 5 }}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ flex: "0 0 50%", paddingRight: 10 }}>

                                <div style={{ paddingBottom: 5 }}>
                                    <MapDataControl title={"Workspace"} data={workspace} />
                                </div>
                                <div style={{ paddingBottom: 5 }}>
                                    <MapDataControl title={"Tags"} data={wsRequest.workspace.tags} />
                                </div>

                            </div>
                            <div style={{ flex: "1" }}>
                                <div>
                                    <div style={{ paddingBottom: 5 }}>
                                        <MapDataControl title={"Compute"} data={compute} />
                                    </div>
                                    <div style={{ paddingBottom: 5 }}>
                                        <MapDataControl title={"Request"} data={request} />
                                    </div>
                                    <div style={{ paddingBottom: 5 }}>
                                        <MapDataControl title={"Review"} data={review} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Card.Content>
        </Card >
    );
}

const WorkspaceRequestForm = ({ input, setResult }) => {
    const { action, uiconfig, data = {} } = input;
    const { config } = uiconfig
    const directories = Object.keys(config.directories)
    const bundleIds = Object.keys(config.bundles);
    const billingCategories = config.tags.billing_category;
    let result = new WorkspaceDialogResult(action);

    const [workspaceRequest, setWorkspaceRequest] = useState(data || new WorkspaceRequest({
        status: RequestStatus.REQUESTED,
        directoryId: directories[0],
        jiraNumber: "",
        bundleId: bundleIds[0],
        billingCategory: billingCategories[0],
        billingSubCategory: ""
    }));

    const [errors, setErrors] = useState({
        jiraNumber: false,
        billingSubCategory: false,
    });

    const validateForm = useCallback(() => {
        const jiraPattern = /^CDSEC-\d+$/;
        const errors = {
            jiraNumber: !jiraPattern.test(workspaceRequest.jiraNumber.trim()),
            billingSubCategory: workspaceRequest.workspace.tags.billingSubcategory.trim() === "",
        };
        setErrors(errors);
        return !Object.values(errors).includes(true);
    }, [workspaceRequest]);

    const isDirty = useCallback(() => {
        const isDirty = data ? !WorkspaceRequest.isEqual(data, workspaceRequest) : false;
        return isDirty;
    }, [workspaceRequest]);

    useEffect(() => {
        result.data = workspaceRequest;
        result.isValid = validateForm();
        result.isDirty = isDirty();
        setResult(result);
    }, [workspaceRequest,
        setResult,
        validateForm, isDirty]);

    return (
        <Card style={{ width: "auto" }}>
            <Card.Content>
                <div style={{ display: "flex" }}>
                    <div
                        style={{
                            flex: "0 0 60%",
                            margin: "5px",
                            padding: "5px",
                        }}
                    >
                        <CustomTextField
                            id="jira-ticket"
                            label="Jira Ticket Id"
                            value={workspaceRequest.jiraNumber}
                            handler={(value) => {
                                setWorkspaceRequest({ ...workspaceRequest, jiraNumber: value })
                            }}
                            isError={errors.jiraNumber}
                            errorMessage="Jira Number is required"
                        />
                        <CustomDropDown
                            id="bundle-selector"
                            label="Select Bundle"
                            value={workspaceRequest.workspace.bundleId}
                            handler={(value) => {
                                setWorkspaceRequest({
                                    ...workspaceRequest,
                                    workspace: {
                                        ...workspaceRequest.workspace, bundleId: value,
                                        tags: {
                                            ...workspaceRequest.workspace.tags
                                        },
                                    },
                                });
                            }}
                            items={bundleIds}
                        />
                        <CustomDropDown
                            id="billingCategory-selector"
                            label="Billing Category"
                            value={workspaceRequest.workspace.tags.billingCategory}
                            handler={(value) => {
                                setWorkspaceRequest({
                                    ...workspaceRequest,
                                    workspace: {
                                        ...workspaceRequest.workspace,
                                        tags: {
                                            ...workspaceRequest.workspace.tags,
                                            billingCategory: value,
                                        },
                                    },
                                });
                            }}
                            items={billingCategories}
                        />
                        <CustomTextField
                            id="billingSubCategory"
                            label="Billing Sub Category"
                            value={workspaceRequest.workspace.tags.billingSubcategory}
                            handler={(value) => {
                                setWorkspaceRequest({
                                    ...workspaceRequest,
                                    workspace: {
                                        ...workspaceRequest.workspace,
                                        tags: {
                                            ...workspaceRequest.workspace.tags,
                                            billingSubcategory: value,
                                        },
                                    },
                                });
                            }}
                            isError={errors.billingSubCategory}
                            errorMessage="Billing Sub Category is required"
                        />
                    </div>
                    <div
                        style={{
                            flex: 1,
                            borderRadius: "5px",
                            border: "1px solid lightgray",
                            margin: "5px",
                            padding: "5px",
                            backgroundColor: "lightcyan",
                        }}
                    >
                        <BundleDescription
                            header="WorkSpace Description"
                            description={config.bundles[workspaceRequest.workspace.bundleId].description}
                        />
                    </div>
                </div>
            </Card.Content>
        </Card>
    );
};

const RenderContent = (action, input, setResult) => {
    let content = null;
    if (action === DialogAction.NEW_REQUEST || action === DialogAction.EDIT_REQUEST) {
        content = (<WorkspaceRequestForm input={input} setResult={setResult} />)
    }
    if (action === DialogAction.WARNING) {
        content = (<WorkspaceConfirmationForm input={input} setResult={setResult} />)
    }
    if (action === DialogAction.WORKSPACE_DETAILS) {
        content = (<WorkspaceDetailsForm input={input} />)
    }
    return content;
}

const WorkSpacesDialogBox = ({ input, onClose, options }) => {
    const { action, uiconfig } = input;
    const [result, setResult] = useState(null);

    const handleClose = (event, reason) => {
        if (reason === "backdropClick") return;
        onClose();
    };

    const readyToSubmit = () => {
        if (!result || !result.isValid) {
            return false;
        }
        if (action === DialogAction.EDIT_REQUEST && !result.isDirty) {
            return false;
        }
        return true;
    };

    const renderDialogActions = () => (
        <div>
            {action === DialogAction.NEW_REQUEST || action === DialogAction.EDIT_REQUEST ? (
                <Button
                    variant="outlined"
                    onClick={() => options.submit(result)}
                    color="primary"
                    disabled={!readyToSubmit()}
                    style={{ marginRight: "0.5em" }}
                >
                    Submit
                </Button>
            ) : null}
            {action === DialogAction.WARNING ? (
                <Button
                    variant="outlined"
                    onClick={() => input.options.onOK(result)}
                    color="primary"
                    disabled={!readyToSubmit()}
                    style={{ marginRight: "0.5em" }}
                >
                    Ok
                </Button>
            ) : null}
            <Button
                variant="outlined"
                onClick={handleClose}
                color="primary"
                style={{ marginRight: "0.5em" }}
            >
                Cancel
            </Button>
        </div>
    );

    return (
        <Dialog
            disableEscapeKeyDown
            open={action !== DialogAction.CLOSE}
            onClose={handleClose}
            aria-labelledby="workspacesformdialog-title"
            fullWidth
            maxWidth={action === DialogAction.WORKSPACE_DETAILS ? "md" : "sm"}
        >
            <DialogTitle id="workspacesformdialog-title" style={{ background: "#f5f5f5" }}>
                <Typography id="title" color="primary" variant="body1">
                    {getTitle(action)}
                </Typography>
            </DialogTitle>
            <DialogContent style={{ minHeight: "100px" }}>
                {RenderContent(action, input, setResult)}
            </DialogContent>
            <DialogActions style={{ background: "#f5f5f5" }}>
                {renderDialogActions()}
            </DialogActions>
        </Dialog>
    );
};

export default WorkSpacesDialogBox;
