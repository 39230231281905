import React from "react";
import ObjectList from "../common/ObjectList";
import {
    Button,
    IconButton,
    Tooltip,
    TextField,
    MenuItem,
    Typography,
} from "@material-ui/core";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import ApproveIcon from "@material-ui/icons/ThumbUp";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import RevokeIcon from "@material-ui/icons/PanTool";
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ComputerIcon from '@material-ui/icons/Computer';
import ScheduleIcon from '@material-ui/icons/Schedule';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import UpdateIcon from '@material-ui/icons/Update';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { RequestStatus, WorkSpaceState } from "../controllers/WorkSpacesController";
import TimerOffIcon from '@material-ui/icons/TimerOff';
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import { Alert } from "@material-ui/lab";
import DesktopAccessDisabledOutlinedIcon from '@material-ui/icons/DesktopAccessDisabledOutlined';
import DesktopWindowsOutlinedIcon from '@material-ui/icons/DesktopWindowsOutlined';


const StatusIcon = ({ status, tooltipTitle, icon, color }) => (
    <div style={{ margin: "0.5em" }}>
        <Tooltip title={tooltipTitle}>
            {React.cloneElement(icon, { color })}
        </Tooltip>
    </div>
);

const getRequestStatusIcon = (status) => {
    switch (status) {
        case RequestStatus.EDITED:
            return <StatusIcon status={status} tooltipTitle="Edited" icon={<HourglassEmptyIcon />} color="secondary" />;
        case RequestStatus.REQUESTED:
            return <StatusIcon status={status} tooltipTitle="Requested" icon={<HourglassEmptyIcon />} color="primary" />;
        case RequestStatus.APPROVED:
            return <StatusIcon status={status} tooltipTitle="Approved" icon={<ApproveIcon />} color="primary" />;
        case RequestStatus.DELETED:
            return <StatusIcon status={status} tooltipTitle="Deleted" icon={<DeleteIcon />} color="secondary" />;
        case RequestStatus.REVOKED:
            return <StatusIcon status={status} tooltipTitle="Revoked" icon={<RevokeIcon />} color="secondary" />;
        case RequestStatus.REJECTED:
            return <StatusIcon status={status} tooltipTitle="Rejected" icon={<ThumbDownIcon />} color="secondary" />;
        default:
            return <StatusIcon status={status} tooltipTitle="Not Available" icon={<ReportProblemIcon />} color="primary" />;
    }
};

const getWorkspaceStateIcon = (state) => {
    switch (state) {
        case WorkSpaceState.PENDING:
            return <StatusIcon tooltipTitle="Scheduled" icon={<ScheduleIcon />} color="primary" />;
        case WorkSpaceState.IN_PROGRESS:
            return <StatusIcon tooltipTitle="In Progress" icon={<UpdateIcon style={{ color: 'orange' }} />} />;
        case WorkSpaceState.TERMINATING:
            return <StatusIcon tooltipTitle="Terminating" icon={<UpdateIcon style={{ color: 'orange' }} />} />;
        case WorkSpaceState.STOPPED:
            return <StatusIcon tooltipTitle="Stopped" icon={<RemoveCircleOutlineIcon />} color="secondary" />;
        case WorkSpaceState.TERMINATED:
            return <StatusIcon tooltipTitle="Terminated" icon={<DeleteForeverIcon />} color="secondary" />;
        case WorkSpaceState.AVAILABLE:
            return <StatusIcon tooltipTitle="Available" icon={<CheckCircleOutlineIcon style={{ color: 'green' }} />} />;
        case WorkSpaceState.TIMEOUT:
            return <StatusIcon tooltipTitle="Timeout" icon={<TimerOffIcon />} color="secondary" />;
        case WorkSpaceState.ERROR:
            return <StatusIcon tooltipTitle="Error" icon={<CancelIcon />} color="secondary" />;
        default:
            return null;
    }
};

const CustomizeDropDown = ({ id, label, value, items, handler }) => (
    <TextField
        id={id}
        label={label}
        value={value}
        onChange={(e) => handler(e.target.value)}
        fullWidth
        select
        variant="standard"
    >
        {items.map((item) => (
            <MenuItem value={item} key={item} >
                {item}
            </MenuItem>
        ))}
    </TextField>
);

const GridTemplate = ({
    title,
    headers,
    data,
    sorting,
    defaultOrderBy,
    filter,
    filterKey,
    filterItems,
    defaultFilter,
    handleSortAndFilterState,
    info }) => {
    const order = sorting.order || "desc";
    const orderBy = sorting.orderBy || defaultOrderBy;
    const filterBy = filterItems.includes(filter.filterBy) ? filter.filterBy : defaultFilter;
    const filterKeys = filterKey.split('.');

    return (
        <div>
            {info && <Alert severity="info">{info}</Alert>}
            <ObjectList
                noPaper
                title={title}
                headers={headers}
                data={filterBy === RequestStatus.ALL ? data : data.filter(item => {
                    const value = filterKeys.reduce((obj, key) => {
                        return obj && obj[key] !== undefined ? obj[key] : undefined;
                    }, item);
                    return (value === filterBy);
                })}
                orderBy={orderBy}
                order={order}
                handleSortAndFilterState={handleSortAndFilterState}
                toolbarComponent={
                    <CustomizeDropDown
                        id={"filter"}
                        label={"Filter"}
                        items={filterItems}
                        value={filterBy}
                        handler={(f) => handleSortAndFilterState("filter", { filterBy: f })}
                    />
                }
            />
        </div>
    );
};

export const WorkspaceRequestHistoryGrid = ({ title, data, sorting, filter, handleSortAndFilterState }) => {
    const headers = [
        {
            id: "status",
            numeric: false,
            disablePadding: false,
            label: "Request Status",
            getCellWidget: n => getRequestStatusIcon(n.status)
        },
        {
            id: "user",
            numeric: false,
            disablePadding: false,
            label: "User",
            getCellWidget: n => <div style={{ fontWeight: "bold" }}>{n.user || "-"}</div>
        },
        {
            id: "createdOn",
            numeric: true,
            disablePadding: false,
            label: "Date",
            getCellWidget: n => <div style={{ textAlign: 'center' }}>{new Date(n.createdOn).toLocaleDateString()}</div>
        },
        {
            id: "activity",
            numeric: false,
            disablePadding: false,
            label: "Activity",
            getCellWidget: n => <div>{n.activity}</div>
        }
    ];

    const filterItems = Object.keys(RequestStatus);
    return (<GridTemplate
        title={title}
        headers={headers}
        data={data}
        sorting={sorting}
        defaultOrderBy={"createdOn"}
        filter={filter}
        filterKey={"status"}
        filterItems={filterItems}
        defaultFilter={RequestStatus.ALL}
        handleSortAndFilterState={handleSortAndFilterState}
        info={"Displaying the most recent 100 user activities."}
    ></GridTemplate>);
};

export const WorkspacePendingRequestsGrid = ({ title, data, options, sorting, filter, handleSortAndFilterState}) => {
    const { config } = options.uiconfig;
    const getComputeDescription=(n)=>{
        const bundle = config.bundles[n.workspace.bundleId];

        return (<div>
            <span><strong>OS:</strong> <i>{bundle.os}</i></span> <br></br>
            <span><strong>Compute:</strong> <i>{bundle.compute_type} - {bundle.compute_description}</i></span> <br></br>
            <span><strong>Root volume:</strong> <i>{bundle.root_volume} GB</i></span> <br></br>
            <span><strong>User volume:</strong> <i>{bundle.user_volume} GB</i></span> <br></br>
            <span><strong> Billing category/subcategory:</strong> <i>{n.workspace.tags.billingCategory}/{n.workspace.tags.billingSubcategory}</i></span> <br></br>
        </div>)
    }
    const headers = [
        {
            id: "status",
            numeric: false,
            disablePadding: false,
            label: "Request Status",
            getCellWidget: n => getRequestStatusIcon(n.status)
        },
        {
            id: "createdOn",
            numeric: true,
            disablePadding: false,
            label: "Created Date",
            getCellWidget: n => <div style={{ textAlign: 'center' }}>{new Date(n.createdOn).toLocaleDateString()}</div>
        },
        {
            id: "jiraNumber",
            numeric: false,
            disablePadding: false,
            label: "JIRA",
            getCellWidget: n => (
                <div style={{ textAlign: 'left', marginLeft: '-20px' }}>
                    {n.jiraNumber ? (
                        <a href={`https://hyland.atlassian.net/browse/${n.jiraNumber}`} target="_blank" rel="noopener noreferrer">
                            {n.jiraNumber}
                        </a>
                    ) : (
                        "-"
                    )}
                </div>
            )
        },
        {
            id: "request.requester",
            numeric: false,
            disablePadding: false,
            label: "Requester",
            getCellWidget: n => <div style={{ fontWeight: "bold" }}>{n.request.requester || "-"}</div>
        },
        {
            id: "bundle_details",
            numeric: false,
            disablePadding: false,
            label: "Bundle Details",
            getCellWidget: n => getComputeDescription(n)
        },
        {
            id: "actions",
            numeric: false,
            disablePadding: false,
            label: "Actions",
            getCellWidget: n => (
                <div style={{ textAlign: 'left', marginLeft: '-60px' }}>
                    <Tooltip title="Approve">
                        <Button color="primary" variant="outlined"
                            style={{ marginRight: "0.5em", marginBottom: "0.5em" }}
                            onClick={() => options.review(RequestStatus.APPROVED, n)}>
                            <ApproveIcon color="primary" />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Reject">
                        <Button color="primary" variant="outlined"
                            style={{ marginRight: "0.5em", marginBottom: "0.5em" }}
                            onClick={() => options.review(RequestStatus.REJECTED, n)}>
                            <ThumbDownIcon style={{ color: 'red' }} />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Edit">
                        <Button color="primary" variant="outlined"
                            style={{ marginRight: "0.5em", marginBottom: "0.5em" }}
                            onClick={() => options.onEdit(n)}>
                            <EditIcon color="primary" />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <Button color="primary" variant="outlined"
                            style={{ marginRight: "0.5em", marginBottom: "0.5em" }}
                            onClick={() => options.onDelete(n)}>
                            <DeleteIcon color="secondary" />
                        </Button>
                    </Tooltip>
                </div>
            )
        }
    ];

    const filterItems = [RequestStatus.ALL, RequestStatus.REQUESTED, RequestStatus.EDITED, RequestStatus.DELETED];
    return (<GridTemplate
        title={title}
        headers={headers}
        data={data}
        sorting={sorting}
        defaultOrderBy={"createdOn"}
        filter={filter}
        filterKey={"status"}
        filterItems={filterItems}
        defaultFilter={RequestStatus.ALL}
        handleSortAndFilterState={handleSortAndFilterState}
    ></GridTemplate>);

};

export const WorkspaceDetailsGrid = ({ title, data, options, sorting, filter, handleSortAndFilterState }) => {
    const connectionState = (state) => {
        if (state === "DISCONNECTED") {
            return <StatusIcon tooltipTitle="Disconnected" icon={<DesktopAccessDisabledOutlinedIcon />} color="primary" />;
        } if (state === "CONNECTED") {
            return <StatusIcon tooltipTitle="Connected" icon={<DesktopWindowsOutlinedIcon />} color="primary" />;
        } else {
            return "--"
        }
    }
    const headers = [
        {
            id: "workspace.state",
            numeric: false,
            disablePadding: false,
            label: "State",
            getCellWidget: n => getWorkspaceStateIcon(n.workspace.state)
        },
        {
            id: "workspace.workspaceId",
            numeric: false,
            disablePadding: false,
            label: "Workspace ID",
            getCellWidget: n => <div>{n.workspace.workspaceId || "-"}</div>
        },
        {
            id: "workspace.IP",
            numeric: false,
            disablePadding: false,
            label: "IP Address",
            getCellWidget: n => <div>{n.workspace.ip || "-"}</div>
        },
        {
            id: "workspace.user",
            numeric: false,
            disablePadding: false,
            label: "User",
            getCellWidget: n => <div style={{ fontWeight: "bold" }}>{n.workspace.user || "-"}</div>
        },
        {
            id: "workspace.connectionState",
            numeric: true,
            disablePadding: false,
            label: "Connection State",
            getCellWidget: n => <div style={{ textAlign: 'center' }}>{connectionState(n.workspace.connectionState)}</div>
        },
        {
            id: "workspace.lastActiveOn",
            numeric: true,
            disablePadding: false,
            label: "Last Active",
            getCellWidget: n => <div style={{ textAlign: 'center' }}>{n.workspace.lastActiveOn ? new Date(n.workspace.lastActiveOn).toLocaleString() : "-"}</div>
        },
        {
            id: "workspace.createdOn",
            numeric: true,
            disablePadding: false,
            label: "Created Date",
            getCellWidget: n => <div style={{ textAlign: 'center' }}>{n.workspace.createdOn ? new Date(n.workspace.createdOn).toLocaleDateString() : "-"}</div>
        },
        {
            id: "actions",
            numeric: false,
            disablePadding: false,
            label: "Actions",
            getCellWidget: n => {
                let disabled = [WorkSpaceState.AVAILABLE, WorkSpaceState.STOPPED, WorkSpaceState.ERROR].indexOf(n.workspace.state) < 0
                return (<div style={{ textAlign: 'left', marginLeft: '-45px' }}>
                    <Button color="primary" variant="outlined" style={{ marginRight: "0.5em", marginBottom: "0.5em" }} disabled={disabled} onClick={() => options.review(RequestStatus.REVOKED, n)}>
                        <Tooltip title={"Terminate"}>
                            <DeleteIcon color={disabled ? "disabled" : "secondary"} />
                        </Tooltip>
                    </Button>
                    <Button color="primary" variant="outlined" style={{ marginRight: "0.5em", marginBottom: "0.5em" }} onClick={() => options.showWorkspaceDetails(n)}>
                        <Tooltip title={"Zoom details"}>
                            <ZoomInIcon />
                        </Tooltip>
                    </Button>
                </div>)
            }
        }
    ];

    const filterItems = Object.keys(WorkSpaceState);
    return (<GridTemplate
        title={title}
        headers={headers}
        data={data}
        sorting={sorting}
        defaultOrderBy={"workspace.createdOn"}
        filter={filter}
        filterKey={"workspace.state"}
        filterItems={filterItems}
        defaultFilter={WorkSpaceState.ALL}
        handleSortAndFilterState={handleSortAndFilterState}
    ></GridTemplate>);
};

export const WorkspaceReviewedRequestsGrid = ({ title, data, sorting, filter, handleSortAndFilterState }) => {
    const headers = [
        {
            id: "status",
            numeric: false,
            disablePadding: false,
            label: "Request Status",
            getCellWidget: n => getRequestStatusIcon(n.status)
        },
        {
            id: "createdOn",
            numeric: true,
            disablePadding: false,
            label: "Created Date",
            getCellWidget: n => (
                <div style={{ textAlign: 'center' }}>
                    {new Date(n.createdOn).toLocaleDateString()}
                </div>
            )
        },
        {
            id: "jiraNumber",
            numeric: false,
            disablePadding: false,
            label: "JIRA",
            getCellWidget: n => (
                <div style={{ textAlign: 'left', marginLeft: '-20px' }}>
                    {n.jiraNumber ? (
                        <a href={`https://hyland.atlassian.net/browse/${n.jiraNumber}`} target="_blank" rel="noopener noreferrer">
                            {n.jiraNumber}
                        </a>
                    ) : (
                        "-"
                    )}
                </div>
            )
        },
        {
            id: "request.requester",
            numeric: false,
            disablePadding: false,
            label: "Requester",
            getCellWidget: n => (
                <div style={{ fontWeight: "bold" }}>
                    {n.request.requester || "-"}
                </div>
            )
        },
        {
            id: "review.reviewedOn",
            numeric: true,
            disablePadding: false,
            label: "Reviewed Date",
            getCellWidget: n => (
                <div style={{ textAlign: 'center' }}>
                    {new Date(n.review.reviewedOn).toLocaleDateString()}
                </div>
            )
        },
        {
            id: "review.reviewer",
            numeric: false,
            disablePadding: false,
            label: "Reviewer",
            getCellWidget: n => (
                <div style={{ fontWeight: "bold" }}>
                    {n.review.reviewer || "-"}
                </div>
            )
        },
    ];

    const filterItems = [RequestStatus.ALL, RequestStatus.APPROVED, RequestStatus.REJECTED, RequestStatus.REVOKED];
    return (<GridTemplate
        title={title}
        headers={headers}
        data={data}
        sorting={sorting}
        defaultOrderBy={"review.reviewedOn"}
        filter={filter}
        filterKey={"status"}
        filterItems={filterItems}
        defaultFilter={RequestStatus.ALL}
        handleSortAndFilterState={handleSortAndFilterState}
        info={"REJECTED and REVOKED requests will be removed after 7 days."}
    ></GridTemplate>);
};