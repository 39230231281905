import { withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import GetAppIcon from "@material-ui/icons/GetApp";
import classNames from "classnames";
import memoize from "memoize-one";
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { connect } from "react-redux";
import ReactSelect from "react-select";
import { toast } from "react-toastify";
import { Tab } from "semantic-ui-react";
import * as uuid from "uuid/v4";
import Breadcrumb from "../common/Breadcrumb";
import { ActionColumn } from "./CloudInventoryLists";
import CloudProjectCosts from "./CloudProjectCosts";
import CloudProjectHistoryScan from "./CloudProjectHistoryScan";
import { Controller } from "../controllers";
import LoadingPanel from "../common/LoadingPanel";
import LogsPanel from "../LogsPanel";
import ObjectList from "../common/ObjectList";
import PanelActions from "../common/PanelActions";
import ReactUserSelector from "../common/ReactUserSelector";
import { blue } from "@material-ui/core/colors";
import {
  Button,
  CardContent,
  CardMedia,
  ClickAwayListener,
  Divider,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControl,
  FormControlLabel,
  Grow,
  InputLabel,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Select,
  Switch,
  TextField,
  Typography,
  CircularProgress
} from "@material-ui/core";

const styles = theme => ({
  card: {
    display: "flex",
    margin: 15
  },
  cardDetails: {
    display: "flex",
    flexDirection: "column"
  },
  content: {
    display: "flex",
    flexDirection: "column"
  },
  cover: {
    width: 88,
    padding: 10,
    maxHeight: 88,
    transition: "0.5s filter linear"
  },
  rightButton: {
    position: "relative",
    flex: 1,
    flexDirection: "row-reverse",
    display: "flex"
  },
  colorSwitchBase: {
    "&$colorChecked": {
      color: blue[500],
      "& + $colorBar": {
        backgroundColor: blue[500]
      }
    }
  },
  colorChecked: {},
  colorBar: {},
  root: {
    minHeight: 380,
    padding: 20,
    marginTop: 20
  },
  addButton: {
    position: "relative",
    flex: 1,
    flexDirection: "row-reverse",
    display: "flex"
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    padding: 0
  },
  libelles: {
    padding: 20
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  description: {
    width: "100%",
    paddingTop: 10,
    paddingBotoom: 10
  },
  addEnv: {
    margin: 20,
    width: "300px"
  },
  details: {
    alignItems: "flex-start"
  },
  column: {
    flexBasis: "33.33%",
    alignItems: "flex-start"
  },
  flexBox: {
    flexDirection: "row",
    padding: "10px"
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0
  },
  htmlDocument: {
    overflow: "auto",
    width: "100%",
    height: "625px"
  },
  downloadButton: {
    float: "right",
    width: "98%",
    marginBottom: "10px",
    display: "flex",
    justifyContent: "end"
  },
  loader: {
    marginRight: "10px",
    marginTop: "5px"
  }
});

export const ServersList = (data, onDetails) => (
  <ObjectList
    title=""
    noToolBar={true}
    noPaper
    headers={[
      {
        id: "hostname",
        numeric: false,
        disablePadding: false,
        label: "Hostname"
      },
      {
        id: "instance-id",
        numeric: false,
        disablePadding: false,
        label: "Instance ID"
      },
      {
        id: "instance-type",
        numeric: false,
        disablePadding: false,
        label: "Type"
      },
      {
        id: "publicIp",
        numeric: false,
        disablePadding: false,
        label: "Public IP"
      },
      {
        id: "lastSeen",
        numeric: false,
        disablePadding: false,
        label: "Last Seen"
      },
      {
        id: "shim_version",
        numeric: false,
        disablePadding: false,
        label: "Nuxify Version"
      },
      ActionColumn("ec2", onDetails)
    ]}
    onItemDoubleClicked={n => {
      onDetails(n, "ec2");
    }}
    data={data}
  />
);

export const Compliances = [
  { key: "pci", label: "PCI/DSS", mandatory: false },
  { key: "soc2", label: "SOC2", mandatory: true }
];

class ServicePanel extends React.Component {
  render() {
    const { classes, enabled, children, title, onActivate, logo, disabled } = this.props;
    const image = `/logos/${logo}`;
    const imgStyle = {};
    if (!enabled) {
      imgStyle.filter = "grayscale(100%)";
    }
    let id = `switch-${title}`;
    return (
      <div className={classes.card}>
        <CardMedia style={imgStyle} className={classes.cover} image={image} title="IDS" />
        <div className={classes.cardDetails}>
          <CardContent className={classes.content}>
            <FormControlLabel
              disabled={disabled}
              control={
                <Switch
                  classes={{
                    switchBase: classes.colorSwitchBase,
                    checked: classes.colorChecked
                  }}
                  onChange={onActivate}
                  checked={enabled}
                  id={id}
                />
              }
              label={title}
            />
            {enabled ? children : null}
          </CardContent>
        </div>
      </div>
    );
  }
}

// React does not push for inheritance but in this case sounds easier
class DatadogServicePanel extends React.Component {
  render() {
    const { classes, onActivate, enabled, config, onConfigChange, defaultConfig } = this.props;
    let api_key = config.api_key || defaultConfig.api_key;
    let logs = config.logs;
    if (logs === undefined) {
      logs = defaultConfig.logs || false;
    }
    return (
      <ServicePanel
        title="Datadog"
        onActivate={onActivate}
        enabled={enabled || false}
        classes={classes}
        disabled={defaultConfig.strict.enabled}
        logo="datadog.png"
      >
        <TextField
          label="API Key"
          value={api_key}
          disabled={defaultConfig.strict.api_key}
          onChange={evt => onConfigChange("api_key", evt.target.value)}
        />
        <FormControlLabel
          control={
            <Switch
              classes={{
                switchBase: classes.colorSwitchBase,
                checked: classes.colorChecked
              }}
              disabled={defaultConfig.strict.logs}
              checked={logs}
              onChange={evt => onConfigChange("logs", evt.target.checked)}
            />
          }
          label="Logs"
        />
      </ServicePanel>
    );
  }
}

class Rapid7ServicePanel extends ServicePanel {
  render() {
    const { onActivate, onConfigChange, enabled, classes, config, defaultConfig } = this.props;
    let api_key = config.api_key || defaultConfig.api_key;

    return (
      <ServicePanel
        title="Rapid7"
        onActivate={onActivate}
        enabled={enabled || false}
        classes={classes}
        logo="rapid7.png"
        disabled={defaultConfig.strict.enabled}
      >
        <TextField
          label="Token"
          value={api_key}
          disabled={defaultConfig.strict.api_key}
          onChange={evt => onConfigChange("api_key", evt.target.value)}
        />
      </ServicePanel>
    );
  }
}

class CrowdStrikeServicePanel extends ServicePanel {
  render() {
    const { onActivate, onConfigChange, enabled, classes, config, defaultConfig } = this.props;
    let api_key = config.api_key || defaultConfig.api_key;
    return (
      <ServicePanel
        title="CrowdStrike"
        onActivate={onActivate}
        enabled={enabled || false}
        classes={classes}
        logo="crowdstrike.png"
        disabled={defaultConfig.strict.enabled}
      >
        <TextField
          label="CID"
          value={api_key}
          disabled={defaultConfig.strict.api_key}
          onChange={evt => onConfigChange("api_key", evt.target.value)}
        />
      </ServicePanel>
    );
  }
}

class DockerServicePanel extends ServicePanel {
  render() {
    const { onActivate, enabled, classes, defaultConfig, onDetails, env, onCreateNewDocker, project } = this.props;
    // formatting array to render ObjectList
    let dockerService = {};
    if (
      project &&
      project.envs &&
      project.envs[env] &&
      project.envs[env].services &&
      project.envs[env].services.docker
    ) {
      dockerService = project.envs[env].services.docker;
    }
    let dockerDatasAsArray = [];

    for (let el in dockerService.containers) {
      let container = dockerService.containers[el];
      container.name = el;
      dockerDatasAsArray.push(container);
    }

    return (
      <ServicePanel
        title="Docker"
        onActivate={onActivate}
        enabled={enabled || false}
        classes={classes}
        logo="docker.png"
        disabled={defaultConfig.strict.enabled}
      >
        <Button
          variant="outlined"
          color="primary"
          className={classes.addEnv}
          onClick={() => onCreateNewDocker(env)}
          id="add-docker-button"
        >
          Add docker container
        </Button>
        {dockerDatasAsArray ? (
          <ObjectList
            title="Containers"
            noToolBar={true}
            noPaper
            headers={[
              {
                id: "name",
                numeric: false,
                disablePadding: false,
                label: "Name"
              },
              {
                id: "image",
                numeric: false,
                disablePadding: false,
                label: "Image"
              },
              {
                id: "volume",
                numeric: false,
                disablePadding: false,
                getCellValue: o => {
                  if (o.volumes !== undefined) {
                    return Object.keys(o.volumes)
                      .map(v => `${v}->${o.volumes[v].bind}(${o.volumes[v].mode})`)
                      .join(",");
                  }
                },
                label: "Volume"
              },
              {
                id: "port",
                numeric: false,
                disablePadding: false,
                getCellValue: o => {
                  if (o.ports !== undefined) {
                    return Object.keys(o.ports)
                      .map(p => `${p}->${o.ports[p]}`)
                      .join(",");
                  }
                },
                label: "Port"
              }
            ]}
            onItemDoubleClicked={n => {
              onDetails(n);
            }}
            data={dockerDatasAsArray}
          />
        ) : (
          <div>
            <p>No docker container yet</p>
          </div>
        )}
      </ServicePanel>
    );
  }
}

class CloudProjectDetailsPanelNoStyle extends React.Component {
  newEnvs = {
    dev: {
      type: "dev",
      label: "Dev",
      services: {}
    },
    uat: {
      type: "uat",
      label: "UAT",
      services: {}
    },
    preprod: {
      type: "preprod",
      label: "Preprod",
      services: {}
    },
    prod: {
      type: "prod",
      label: "Prod",
      services: {}
    },
    staging: {
      type: "staging",
      label: "Staging",
      services: {}
    }
  };
  state = {
    open: false,
    envs: this.getEnvs(this.props.project.envs) || {},
    downloadforEnv: "",
    drafts: {
      description: this.props.project.description || "",
      owners: this.props.project.owners || [],
      compliance: this.getComplianceObject(),
      override: this.props.project.override || false
    }
  };

  getEnvs(src) {
    let envs = {};
    for (let i in src) {
      // Deep copy for now
      envs[i] = JSON.parse(JSON.stringify(src[i]));
      envs[i]._dirty = false;
    }
    return envs;
  }

  filterNewEnvs = memoize(envs => {
    let res = [];
    for (let i in this.newEnvs) {
      if (!envs[i]) {
        res.push(this.newEnvs[i]);
      }
    }
    return res;
  });

  getComplianceObject() {
    let result = this.props.project.compliance || {};
    Compliances.forEach(info => {
      if (result[info.key] === undefined) {
        result[info.key] = info.mandatory;
      }
    });
    return result;
  }

  onChange = field => {
    return event => {
      let value = event.target.value? event.target.value : "";
      this.setState(prevState => {
        let res = { ...prevState };
        res.drafts[field] = value;
        res.dirty = true;
        return res;
      });
    };
  };

  onUserUpdate = field => {
    return value => {
      this.setState(prevState => {
        let res = { ...prevState };
        res.drafts[field] = value;
        res.dirty = true;
        return res;
      });
    };
  };

  onEnvUserUpdate = (type, field) => {
    return value => {
      this.setState(prevState => {
        let res = { ...prevState };
        res.envs[type][field] = value;
        res.envs[type]._dirty = true;
        return res;
      });
    };
  };

  onEnvServicesActivate = (type, field) => {
    return value => {
      let servicesValue = value.target.checked;
      this.setState(prevState => {
        let res = { ...prevState };
        if (!res.envs[type].services[field]) {
          res.envs[type].services[field] = {};
        }
        res.envs[type].services[field].enabled = servicesValue;
        res.envs[type]._dirty = true;
        return res;
      });
    };
  };

  onEnvServicesUpdate = (type, service) => {
    return (name, value) => {
      this.setState(prevState => {
        let res = { ...prevState };
        res.envs[type].services[service][name] = value;
        res.envs[type]._dirty = true;
        return res;
      });
    };
  };

  onComplianceUpdate = field => {
    return value => {
      let complianceValue = value.target.checked;
      this.setState(prevState => {
        let res = { ...prevState };
        res.drafts.compliance[field] = complianceValue;
        res.drafts.dirty = true;
        return res;
      });
    };
  };

  onCancel = () => {
    this.setState((oldState, props) => {
      let res = { ...oldState };
      let project = props.project;
      res.drafts.compliance = project.compliance;
      res.drafts.owners = project.owners;
      res.drafts.override = project.override;
      res.drafts.description = project.description;
      res.dirty = false;
      for (let env in res.envs) {
        if (env._darty) {
          res.dirty = true;
        }
      }
      return res;
    });
  };

  onSave = () => {
    this.props.onSave(this.state.drafts, () => {
      this.setState({
        dirty: false
      });
    }, "compliance-scope");
    if (this.state.drafts.override === false && this.props.project.override) {
      this.setState(oldState => {
        let envs = oldState.envs;
        for (let i in envs) {
          for (let k in envs[i].services) {
            // Revert all strict values
            let config = Controller.get("cloudprojects").getDefaultConfig(k, i, false);
            for (let l in config.strict) {
              envs[i].services[k][l] = config[l];
            }
          }
        }
        return { envs };
      });
    }
  };

  onEnvCancel = type => {
    return () => {
      this.setState((oldState, props) => {
        let envs = { ...oldState.envs };
        // Deep copy
        if (props.project.envs && props.project.envs[type] !== undefined) {
          envs[type] = JSON.parse(JSON.stringify(props.project.envs[type]));
          envs[type]._dirty = false;
        } else {
          // env not saved yet, should be cleaned with cancel
          // was crashing before if trying to cancel an env creation
          envs[type]._dirty = false;
          envs = props.project.envs || {};
        }
        return {
          envs
        };
      });
    };
  };



  onEnvSave = type => {
    return () => {
      let envs = this.state.envs;
      this.props.onSave({ envs }, () => {
        this.setState(prevState => {
          let envs = prevState.envs;
          envs[type]._dirty = false;
          return {
            envs
          };
        });
      }, "environment");
    };
  };


  handleEnvironmentCreation = type => {
    return event => {
      if (type) {
        // Create env now
        this.setState(prevState => {
          let el = { ...this.newEnvs[type] };
          let base64UUID = new Buffer(uuid("binary"), "binary").toString("base64");
          base64UUID = base64UUID.replace(/=/g, "").replace(/\//g, ".").replace(/\+/g, "_");
          el.secretKey = base64UUID;
          el._dirty = true;
          ["datadog", "rapid7", "crowdstrike"].forEach(service => {
            el.services[service] = Controller.get("cloudprojects").getDefaultConfig(service, type);
          });

          let envs = { ...prevState.envs };
          envs[el.type] = el;
          return {
            envs
          };
        });
      }
      if (this.anchorEl.contains(event.target)) {
        return;
      }

      this.setState({ open: false });
    };
  };

  handleMenuToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  onDockerServiceDetail = env => {
    return evt => {
      this.props.history.push(`${this.props.history.location.pathname}/${env}/container/${evt.name}`);
    };
  };
  onCreateNewDocker = env => {
    return evt => {
      this.props.history.push(`${this.props.history.location.pathname}/${env}/container/new`);
    };
  };

  handleServerDetails = (server, type) => {
    this.props.history.push(`${this.props.history.location.pathname}/${server.uuid}`);
  };

  render() {
    const {
      classes,
      asyncGet,
      asyncPut,
      asyncGetCost,
      asyncGetServers,
      projectServers,
      project,
      projectCost,
      currentUser,
      rulesOptions,
      asyncDownloadPdf
    } = this.props;
    const { open, envs, drafts, dirty } = this.state;
    const newEnvs = this.filterNewEnvs(envs);
    let override;
    if (currentUser && Controller.get("users").hasPermission("cloudProjectsOverride")) {
      override = (
        <FormControlLabel
          control={
            <Switch
              classes={{
                switchBase: classes.colorSwitchBase,
                checked: classes.colorChecked
              }}
              onChange={evt => {
                let value = evt.target.checked;
                this.setState(prevState => {
                  return {
                    drafts: { ...prevState.drafts, override: value },
                    dirty: true
                  };
                });
              }}
              checked={drafts.override}
            />
          }
          label="Security Override"
        />
      );
    }
    const isEmptyDescription = (drafts.description && drafts.description !== ""? false : true)
    const actionDisabled =  isEmptyDescription || (drafts.override && !Controller.get("users").hasPermission("cloudProjectsOverride"));
    
    let iAmOwner = Controller.get("users").isGrantedByThisList(this.props.project.owners);
    return (
      <div>
        <LoadingPanel async={asyncGet}>
          <Paper className={classes.root} id="cloudProjectDetailContainer">
            {this.props.children}
            <form className={classes.container} noValidate autoComplete="off">
              <TextField
                id="multiline-static"
                label="Description"
                multiline
                minRows="4"
                value={drafts.description}
                onChange={this.onChange("description")}
                className={(classes.textField, classes.description)}
                margin="normal"
                helperText={!drafts.description?.trim() ? "Description is required" : ""}
                error={!drafts.description?.trim()}
              />
              <p className={classes.secondaryHeading}>Compliance scope</p>
              {Compliances.map((info, i) => (
                <div key={i}>
                  <FormControlLabel
                    control={
                      <Switch
                        classes={{
                          switchBase: classes.colorSwitchBase,
                          checked: classes.colorChecked
                        }}
                        onChange={this.onComplianceUpdate(info.key)}
                        checked={drafts.compliance[info.key]}
                        value={info.key}
                        disabled={info.mandatory}
                      />
                    }
                    label={info.label}
                  />
                </div>
              ))}
              <ReactUserSelector label="Project Owners" onUpdate={this.onUserUpdate("owners")} value={drafts.owners} />
              {override}
              <PanelActions
                onCancel={this.onCancel}
                onSave={this.onSave}
                dirty={dirty}
                async={asyncPut}
                disabled={actionDisabled}
                error={actionDisabled ? isEmptyDescription? "Description can not be empty. ":"Security override - only security team can take action" : undefined}
              />
              <div>
                {iAmOwner && (
                  <Button
                    variant="outlined"
                    color="primary"
                    buttonRef={node => {
                      this.anchorEl = node;
                    }}
                    aria-owns={open ? "menu-list-grow" : null}
                    aria-haspopup="true"
                    onClick={this.handleMenuToggle}
                    className={classes.addEnv}
                  >
                    Add environment
                  </Button>
                )}
                <Popper open={open} anchorEl={this.anchorEl} transition disablePortal style={{ zIndex: 1000 }}>
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      id="menu-list-grow"
                      style={{
                        transformOrigin: placement === "bottom" ? "center top" : "center bottom"
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={this.handleEnvironmentCreation()}>
                          <MenuList>
                            {newEnvs.map((n, id) => {
                              return (
                                <MenuItem
                                  key={id}
                                  onClick={this.handleEnvironmentCreation(n.type)}
                                  id={`add-${n.type}-env`}
                                >
                                  {n.label}
                                </MenuItem>
                              );
                            })}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            </form>
          </Paper>
          {Object.keys(envs).map((key, id) => {
            let n = envs[key];
            let usersCount = 0;
            let servicesCount = 0;
            let serversCount = 0;
            let privilegedUsersCount = 0;
            let normalUsersCount = 0;
            let envCosts = projectCost[key];
            if (!n.services) {
              n.services = {
                rapid7: { enabled: false, config: {} },
                crowdstrike: { enabled: false, config: {} },
                datadog: { enabled: false, config: {}, logs: false },
                docker: { enabled: false, config: {} }
              };
            }
            if (!n.services.docker) {
              n.services.docker = { enabled: false, config: {} };
            }
            if (!n.services.rapid7) {
              n.services.rapid7 = { enabled: false, config: {} };
            }
            if (!n.services.crowdstrike) {
              n.services.crowdstrike = { enabled: false, config: {} };
            }
            if (n.privilegedUsers) {
              privilegedUsersCount = Object.keys(n.privilegedUsers).length;
            }
            if (n.users) {
              normalUsersCount = Object.keys(n.users).length;
            }
            usersCount = privilegedUsersCount + normalUsersCount;
            for (var serviceType in n.services) {
              if (n.services[serviceType].enabled === true) {
                servicesCount++;
              }
            }
            let saveActions;
            let envServers = (projectServers || this.props.project.servers || []).filter(s => s.environment === key);

            //Enabeling nuxify debuging from localhost and dev environment
            let debug = false;
            let protocol = "https";
            let apiHost = "api.soc.nuxeo.com";

            const origin = window.location.origin;
            const isDevEnvironment = origin.match(/^https:\/\/([^.]+)\.dev\.soc\.nuxeo\.com$/);
            const isLocalhost = window.location.hostname === "localhost";
            const isBetaEnvironment = window.location.host === "beta.soc.nuxeo.com";

            if (isDevEnvironment) {
              const match = origin.match(/^https:\/\/([^.]+)\.dev\.soc\.nuxeo\.com$/);
              const prNumber = match[1];
              apiHost = `dev.api.soc.nuxeo.com/pr-${prNumber}`;
              debug = true;
            } else if (isLocalhost) {
              apiHost = "localhost:18080";
              protocol = "http";
              debug = true;
            } else if (isBetaEnvironment) {
              apiHost = "beta.api.soc.nuxeo.com";
              debug = true;
            }

            let devEnvVariables = `${(isLocalhost ? "localhost=true" : "")} ${(debug ? "debug=true" : "")}`
            // Remove sorting for now
            serversCount = envServers.length;
            let installInfo = `curl -1 -sSk "${protocol}://${apiHost}/installer.sh" | api_key="${n.secretKey}" api_id="${this.props.project.uuid}_${key}_nuxify" company_name="Nuxeo" project_name="${this.props.project.billingSubcategory}" static_host="${apiHost}" shim_host="${apiHost}" ${devEnvVariables} sudo -s -E`;

            if (n._dirty) {
              saveActions = (
                <PanelActions
                  dirty={n._dirty}
                  onSave={this.onEnvSave(n.type)}
                  onCancel={this.onEnvCancel(n.type)}
                  async={asyncPut}
                  disabled={actionDisabled}
                  error={actionDisabled ? "Security override - only security team can take action" : undefined}
                />
              );
            }

            let CostPanel = null;
            if (Controller.get("users").hasPermission("cloudProjectsCosts")) {
              CostPanel = (
                <div>
                  <ExpansionPanelDetails className={classes.container}>
                    <div>
                      <Typography variant="caption" className={classes.libelles}>
                        Costs
                      </Typography>
                    </div>
                    <div style={{ overflow: "auto", width: "100%" }}>
                      <LoadingPanel async={asyncGetCost}>
                        <CloudProjectCosts data={envCosts} />
                      </LoadingPanel>
                    </div>
                  </ExpansionPanelDetails>
                  <Divider />
                </div>
              );
            }
            let logsPanel = null;
            if (
              Controller.get("users").isGrantedByThisList(n.logsUsers) ||
              Controller.get("users").isGrantedByThisList(n.privilegedUsers)
            ) {
              logsPanel = (
                <LogsPanel
                  events={currentUser.events}
                  user={currentUser.login}
                  noPaper={true}
                  env={key}
                  project={project.uuid}
                />
              );
            }

            let serviceTab = {
              menuItem: "Services",
              render: () => (
                <Tab.Pane>
                  <div className={classNames(classes.column, classes.helper)} id="services-pane">
                    <Typography className={classes.description} variant="caption">
                      Services
                    </Typography>
                    <div
                      style={{
                        flexWrap: "wrap",
                        display: "flex",
                        justifyContent: "space-between"
                      }}
                    >
                      <DatadogServicePanel
                        classes={classes}
                        enabled={n.services.datadog.enabled}
                        config={{
                          ...n.services.datadog.config,
                          ...n.services.datadog
                        }}
                        defaultConfig={Controller.get("cloudprojects").getDefaultConfig(
                          "datadog",
                          n.type,
                          project.override
                        )}
                        onActivate={this.onEnvServicesActivate(n.type, "datadog")}
                        onConfigChange={this.onEnvServicesUpdate(n.type, "datadog")}
                      />
                      <Rapid7ServicePanel
                        classes={classes}
                        enabled={n.services.rapid7.enabled}
                        config={n.services.rapid7}
                        defaultConfig={Controller.get("cloudprojects").getDefaultConfig(
                          "rapid7",
                          n.type,
                          project.override
                        )}
                        onActivate={this.onEnvServicesActivate(n.type, "rapid7")}
                        onConfigChange={this.onEnvServicesUpdate(n.type, "rapid7")}
                      />
                      <CrowdStrikeServicePanel
                        classes={classes}
                        enabled={n.services.crowdstrike.enabled}
                        config={n.services.crowdstrike}
                        defaultConfig={Controller.get("cloudprojects").getDefaultConfig(
                          "crowdstrike",
                          n.type,
                          project.override
                        )}
                        onActivate={this.onEnvServicesActivate(n.type, "crowdstrike")}
                        onConfigChange={this.onEnvServicesUpdate(n.type, "crowdstrike")}
                      />
                      <DockerServicePanel
                        project={project}
                        classes={classes}
                        config={n.services.docker}
                        enabled={n.services.docker.enabled}
                        defaultConfig={Controller.get("cloudprojects").getDefaultConfig(
                          "docker",
                          n.type,
                          project.override
                        )}
                        onActivate={this.onEnvServicesActivate(n.type, "docker")}
                        onConfigChange={this.onEnvServicesUpdate(n.type, "docker")}
                        env={n.type}
                        onDetails={this.onDockerServiceDetail(n.type)}
                        onCreateNewDocker={this.onCreateNewDocker(n.type)}
                        id="docker-service"
                      />
                    </div>
                  </div>
                </Tab.Pane>
              )
            },
              serverTab = {
                menuItem: "Servers",
                render: () => (
                  <Tab.Pane>
                    <div>
                      <Typography variant="caption" className={classes.libelles}>
                        Servers
                      </Typography>
                    </div>
                    <div style={{ overflow: "auto", width: "100%" }}>
                      <LoadingPanel async={asyncGetServers}>{ServersList(envServers, this.handleServerDetails)}</LoadingPanel>
                    </div>
                  </Tab.Pane>
                )
              },
              privilegeTab = {
                menuItem: "Privileges",
                render: () => (
                  <Tab.Pane>
                    <div className={classes.column}>
                      <Typography className={classes.description} variant="caption">
                        Privileged Users
                      </Typography>
                      <ReactUserSelector
                        className={classes.padded}
                        label="Privileged Users"
                        onUpdate={this.onEnvUserUpdate(n.type, "privilegedUsers")}
                        value={n.privilegedUsers}
                      />
                    </div>
                    <div className={classes.column}>
                      <Typography className={classes.description} variant="caption">
                        Users
                      </Typography>
                      <ReactUserSelector
                        label="Users"
                        onUpdate={this.onEnvUserUpdate(n.type, "users")}
                        value={n.users}
                      />
                    </div>
                    <div className={classes.column}>
                      <Typography className={classes.description} variant="caption">
                        Logs Users
                      </Typography>
                      <ReactUserSelector
                        className={classes.padded}
                        label="Logs Users"
                        onUpdate={this.onEnvUserUpdate(n.type, "logsUsers")}
                        value={n.logsUsers}
                      />
                    </div>
                  </Tab.Pane>
                )
              },
              installTab = {
                menuItem: "Installation",
                render: () => (
                  <Tab.Pane>
                    <div className={classes.column}>
                      <TextField
                        id="multiline-static"
                        label="Environment secret key"
                        rows="4"
                        value={n.secretKey}
                        className={(classes.textField, classes.description)}
                        margin="normal"
                        disabled
                      />
                      <div className={classes.rightButton}>
                        <CopyToClipboard
                          text={installInfo}
                          onCopy={() => {
                            toast.info("Bash script installation command has been copied into your copy buffer !", {
                              position: toast.POSITION.BOTTOM_CENTER
                            });
                          }}
                        >
                          <Button>
                            Install <GetAppIcon />
                          </Button>
                        </CopyToClipboard>
                      </div>
                    </div>
                  </Tab.Pane>
                )
              },
              logTab = {
                menuItem: "Logs",
                render: () => <Tab.Pane style={{ padding: 0, border: 0 }}>{logsPanel}</Tab.Pane>
              },
              costTab = {
                menuItem: "Costs",
                render: props => {
                  return <Tab.Pane>{CostPanel}</Tab.Pane>;
                }
              },
              lastScanTab = {
                menuItem: "Last Scan",
                render: () => (
                  <Tab.Pane style={{ height: "700px" }}>
                    <div>
                      <ExpansionPanelDetails className={classes.container}>
                        <div>
                          <Typography variant="caption" className={classes.libelles}>
                            Last Scan
                          </Typography>
                        </div>
                        {n.nessusReport && n.nessusReport !== "No scan available" && (
                          <div className={classes.downloadButton} >
                            <div className={classes.loader}>
                              {asyncDownloadPdf.syncing && this.state.downloadforEnv === n.type ? (
                                <CircularProgress size={20} />
                              ) : null}
                            </div>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                this.setState({ downloadforEnv: n.type });
                                Controller.get("cloudprojects").getPdf(this.props.project.uuid, n.type);
                              }}
                              style={{ float: "right" }}
                            >
                              Download Last Scan Report
                            </Button>
                          </div>
                        )}
                        <div
                          className={classes.htmlDocument}
                          dangerouslySetInnerHTML={{ __html: n.nessusReport }}
                        ></div>
                      </ExpansionPanelDetails>
                      <Divider />
                    </div>
                  </Tab.Pane>
                )
              },
              scanHistoryTab = {
                menuItem: "Scan History",
                render: () => (
                  <Tab.Pane>
                    <div>
                      <Typography variant="caption" className={classes.libelles}>
                        Scan History
                      </Typography>
                      <div>
                        {n.scanHistory && <CloudProjectHistoryScan data={n.scanHistory} />}
                      </div>
                    </div>
                  </Tab.Pane>
                )
              };

            let panes = [installTab, privilegeTab, serviceTab, serverTab];

            // Hide log tab is not granted
            if (
              Controller.get("users").isGrantedByThisList(n.logsUsers) ||
              Controller.get("users").isGrantedByThisList(n.privilegedUsers)
            ) {
              panes.push(logTab);
            }

            // Hide cost tab is not granted
            if (Controller.get("users").hasPermission("cloudProjectsCosts")) {
              panes.push(costTab);
            }
            panes.push(lastScanTab);
            panes.push(scanHistoryTab);
            return (
              <ExpansionPanel key={id}>
                <ExpansionPanelSummary className={classes.libelles} expandIcon={<ExpandMoreIcon id="expand-icon" />}>
                  <Typography className={classes.heading}>Environment: {n.label}</Typography>
                  <Typography className={classes.secondaryHeading}>
                    ({usersCount} users - {servicesCount} services - {serversCount} servers)
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classNames(classes.container, classes.libelles)}>
                  <Tab
                    id="tab"
                    onChange={this.onChange}
                    menu={{ color: "blue", secondary: true, pointing: true }}
                    panes={panes}
                  />
                </ExpansionPanelDetails>
                <Divider hidden={!n._dirty} />
                {saveActions}
              </ExpansionPanel>
            );
          })}
        </LoadingPanel>
      </div>
    );
  }
}

const CloudProjectDetailsPanel = withStyles(styles)(CloudProjectDetailsPanelNoStyle);

export { CloudProjectDetailsPanel };

class CloudProjectDetails extends React.Component {
  onSave = (updates, done, from) => {
    Controller.get("cloudprojects").update(this.props.project.uuid, updates, done);
  };

  render() {
    const { title } = this.props;
    return (
      <CloudProjectDetailsPanel onSave={this.onSave} {...this.props}>
        <Breadcrumb items={[{ title: "Cloud Projects", path: "/cloudprojects" }, { title }]} />
      </CloudProjectDetailsPanel>
    );
  }
}

export default withStyles(styles)(
  connect((state, ownProps) => {
    let title = "";
    if (ownProps.match) {
      title = ownProps.match.params.id;
    }
    let cloudproject = state.cloudprojects.current || {};
    return {
      title: title,
      key: cloudproject.uuid,
      project: cloudproject,
      projectCost: state.cloudprojects.currentCost || {},
      projectServers: state.cloudprojects.currentServers,
      currentUser: state.users.me || { groups: [] },
      defaultService: state.cloudprojects.defaultServices,
      asyncPut: state.cloudprojects._async.UPDATE_CLOUDPROJECT || {},
      asyncGet: state.cloudprojects._async.GET_CLOUDPROJECT || {},
      asyncGetCost: state.cloudprojects._async.GET_CLOUDPROJECT_COST || {},
      asyncGetServers: state.cloudprojects._async.GET_CLOUDPROJECT_SERVERS || {},
      asyncDownloadPdf: state.cloudprojects._async.GET_CLOUDPROJECT_LAST_SCAN_PDF || {},
      rulesOptions: state.dashboard.rulesOptions || {}
    };
  })(CloudProjectDetails)
);
